import React, { useState } from 'react';
import './services.css';

const Services = () => {
    const [toggleState, setToggleState] = useState(0);
    const toggleTab = (index) => {
        setToggleState(index);
    }

  return (
    <section className="services section" id="services">
        <h2 className="section__title"><a href='/projects' className='little__link'>Mes Projets</a></h2>
        <span className="section__subtitle"> </span>

        <div className="service__container container grid">
            {/* Projet 1 */}
            <div className="services__content">
                <div>
                    <i className="uil uil-web-grid services__icon"></i>
                    <h3 className="services__title">Maintenance et Évolution de TMS</h3>
                </div>
                
                <div>
                    <span className="services__button" onClick={() => toggleTab(1)}>Résumer<i className="uil uil-arrow-right services__button-icon"></i></span>
                </div>
                <div>
                    <span className="services__button"><a href='/projects?id=projetTMS' className='little__link'>En savoir plus<i className="uil uil-arrow-right services__button-icon"></i></a></span>
                </div>
                <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                        <h3 className="services__modal-title">Maintenance et Évolution de Logiciels TMS chez Teliae</h3>
                        <p className="services__modal-description"> Je travaille chez Teliae, une entreprise spécialisée dans les systèmes de gestion des transports (TMS). Mon rôle consiste à assurer la maintenance et à développer des évolutions pour trois applications principales : <br/> Teliway, une solution de gestion des trajets et des itinéraires ; <br/> Station-chargeur, solution  offrant plus de 800 formats d'étiquettes conformes aux exigences des transporteurs, ainsi qu'un suivi complet des expéditions avec notifications en temps réel pour améliorer l'expérience client. ; <br/> Telimobile, une application mobile pour les conducteurs et les gestionnaires de flotte. <br/> Mon travail contribue à améliorer l'efficacité opérationnelle et à garantir la fiabilité de nos solutions logicielles pour répondre aux besoins dynamiques de nos clients.</p>
                        <ul className="services__modal-services grid">
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/projects?id=projetTMS' className='little__link'>Developpement de TMS</a></p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/skills?id=php' className='little__link'>Developpement web</a></p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/skills?id=java' className='little__link'>Developpement Mobile</a></p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/skills?id=windev' className='little__link'>Developpement d'application lourd</a></p>
                            </li>
                        </ul>
                    </div>  
                </div>
            </div>

            {/* Projet 2 */}
            <div className="services__content">
                <div>
                    <i className="uil uil-arrow services__icon"></i>
                    <h3 className="services__title">Refonte de la Page d'Accueil de Station-Chargeur</h3>
                </div>
                
                <div>
                    <span className="services__button" onClick={() => toggleTab(2)}>Résumer<i className="uil uil-arrow-right services__button-icon"></i></span>
                </div>
                <div>
                    <span className="services__button"><a href='/projects?id=projetSC' className='little__link'>En savoir plus<i className="uil uil-arrow-right services__button-icon"></i></a></span>
                </div>
                <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                        <h3 className="services__modal-title">Refonte de la Page d'Accueil de Station-Chargeur avec Intégration de Widgets Dynamiques</h3>
                        <p className="services__modal-description"> J'ai mené à bien la refonte de la page d'accueil de l'application Station-Chargeur, en y intégrant un système de widgets dynamiques. Cette nouvelle page d'accueil permet désormais d'afficher des statistiques en temps réel sur l'exploitation en cours, des messages d'information, des images, et diverses données pertinentes, offrant ainsi une expérience utilisateur améliorée et plus interactive. Cette interface est personnalisable pour chaque utilisateurs.</p>
                        <ul className="services__modal-services grid">
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/skills?id=htmlcss' className='little__link'>HTML, CSS</a>, <a href='/skills?id=javascript' className='little__link'>JAVASCRIPT</a></p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/skills?id=php' className='little__link'>PHP</a></p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/skills?id=autonomie' className='little__link'>Autonomies</a></p>
                            </li>
                        </ul>
                    </div>  
                </div>
            </div>

            {/* Projet 3 */}
            <div className="services__content">
                <div>
                    <i className="uil uil-edit services__icon"></i>
                    <h3 className="services__title">MonPyroStock</h3>
                </div>
                
                <div>
                    <span className="services__button" onClick={() => toggleTab(3)}>Résumer<i className="uil uil-arrow-right services__button-icon"></i></span>
                </div>
                <div>
                    <span className="services__button"><a href='/projects?id=projetMPS' className='little__link'>En savoir plus<i className="uil uil-arrow-right services__button-icon"></i></a></span>
                </div>
                <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)}  className="uil uil-times services__modal-close"></i>
                        <h3 className="services__modal-title">MonPyroStock : Application Web de Gestion de Stocks de Feux d'Artifice</h3>
                        <p className="services__modal-description"> MonPyroStock est une application web conçue spécifiquement pour les artificiers afin de gérer efficacement leurs stocks de feux d'artifice. L'application permet un suivi détaillé des inventaires, des niveaux de stock en temps réel et des alertes automatiques pour les réapprovisionnements nécessaires. Les utilisateurs peuvent enregistrer et organiser les feux d'artifice par catégorie, date de péremption, et fournisseur, tout en générant des rapports d'inventaire personnalisés. Une interface intuitive et des notifications en temps réel garantissent une gestion optimisée des stocks et une préparation sans faille des événements pyrotechniques.</p>
                        <ul className="services__modal-services grid">
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/skills?id=conceptionlogiciel' className='little__link'>Développement d'outils</a></p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/skills?id=php' className='little__link'>Developpement web</a></p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/skills?id=communication' className='little__link'>Contact client</a></p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info"><a href='/skills?id=deploiementweb' className='little__link'>Déploiment web</a></p>
                            </li>
                        </ul>
                    </div>  
                </div>
            </div>
        </div>
    <h3 className="section__title"><a href='/projects'>Plus d'information ici</a></h3>
    </section>
  );
};

export default Services