import React, { useState } from 'react';
import './card.css';
import './cardProject.css'
import handIcon from '../../assets/Hand-Pointer.png';
import Modal from './Modal';

const Card =  ({ idcard, image, title, summary, description, softSkills, hardSkills, showHandIcon, experiences, isSelected, onCloseModal }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const closeModal = () => {
    setIsExpanded(false);
    onCloseModal();
  };

  return (
    <div className="card" id={idcard}>
      {!isExpanded && showHandIcon && <img src={handIcon} alt="Click icon" className="hand-icon" />}
      <div className="card-header" onClick={handleExpandClick}>
        <img src={image} alt={title} className="card-image" />
        <div className="card-content">
          <h3>{title}</h3>
          <p className='summary'>{summary}</p>
        </div>
      </div>

      <Modal show={isExpanded || isSelected } onClose={closeModal}>
        <div className="modal-header">
          <h3>{title}</h3>
        </div>
        <div className="modal-body modal-body-Project">
          <img src={image} alt={title} className="modal-image" />
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
          <div className="modal-details">
            <div>
              <h4>Soft Skills</h4>
              <ul>
                {softSkills.map(skill => (
                  <li key={skill.name}>
                    <a href={skill.link} rel="noopener noreferrer">{skill.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4>Hard Skills</h4>
              <ul>
                {hardSkills.map(skill => (
                  <li key={skill.name}>
                    <a href={skill.link} rel="noopener noreferrer">{skill.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            {experiences && experiences.length > 0 && (
              <div>
                <h4>Experiences</h4>
                <ul>
                  {experiences.map(exp => (
                    <li key={exp.name}>
                      <a href={exp.link} rel="noopener noreferrer">{exp.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Card;