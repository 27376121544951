import React from 'react'

const Soft = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title"Frontend developer>Mes Soft skills</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=curiosite' className='little__link'>Curiosité</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=communication' className='little__link'>Communication</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=teamspirit' className='little__link'>Esprit d'équipe</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=empathie' className='little__link'>Empathie</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=innovation' className='little__link'>Innovation</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=autonomie' className='little__link'>Autonomies</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Soft