import React from 'react'
import Header from '../headers/Headers';
import Home from '../home/Home';
import About from '../about/About';
import Skills from '../skills/Skills';
import Services from '../services/Services';
import Qualification from '../qualification/Qualification';
import ContactLink from '../Contact/ContactLink'

const Accueil = () => {
  return (
    <>
    <Header />

    <main className='main'>
      <Home />
      <About />
      <Skills />
      <Services />
      <Qualification />
      <ContactLink />
    </main>
    </>
  )
}

export default Accueil