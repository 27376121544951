import React from 'react';

const Social = () => {
  return (
    <div className="home__social">

        <a href="https://www.linkedin.com/in/romain-bonjour-95541b199/" className="home__social-icon" target='_blank' rel="noopener noreferrer"><i className="uil uil-linkedin"></i></a>
        <a href="https://github.com/romain-bonjour" className="home__social-icon" target='_blank' rel="noopener noreferrer"><i className="uil uil-github"></i></a>
    </div>
  );
};

export default Social