import React, { useState } from 'react';
import './card.css';
import './skillCard.css';
import Modal from './Modal';

const SkillCard = ({ skill ,  isSelected, onCloseModal}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  const closeModal = () => {
    setIsOpen(false);
    onCloseModal();
  };

  return (
    <div className="skill-card" id={skill.id}>
      <div className="skill-card-header" onClick={toggleCard}>
        {skill.image && <img src={skill.image} alt={skill.name} className="skill-card-image" />}
        <h2>{skill.name}</h2>
      </div>

      <Modal show={isOpen || isSelected } onClose={closeModal}>
        <div className="modal-header">
          <h2>{skill.name}</h2>
          <button className="close-button" onClick={closeModal}>X</button>
        </div>
        <div className="modal-body">
          {skill.image && <img src={skill.image} alt={skill.name} className="modal-image" />}
          <p dangerouslySetInnerHTML={{ __html: skill.description }}></p>
          <div className="skil-container">
            <h4>Projet lier:</h4>
            <ul>
              {skill.projects.map(project => (
                <li key={project.name}>
                  <a href={project.link} rel="noopener noreferrer">{project.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SkillCard;
