import React, { useState } from 'react';
import './card.css';
import './modal.css';
import handIcon from '../../assets/Hand-Pointer.png';
import Modal from './Modal';

const CardExperiences = ({ idcard, image, title, summary, description, softSkills, hardSkills, showHandIcon, projets, isSelected, onCloseModal}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const closeModal = () => {
    setIsExpanded(false);
    onCloseModal();
  };

  return (
    <div className="card" id={idcard}>
      {!isExpanded && showHandIcon && <img src={handIcon} alt="Click icon" className="hand-icon" />}
      <div className="card-header" onClick={handleExpandClick}>
        <img src={image} alt={title} className="card-image" />
        <div className="card-content">
          <h3>{title}</h3>
          <p className='summary'>{summary}</p>
        </div>
      </div>
      <Modal show={isExpanded || isSelected } onClose={closeModal}>
        <div className="modal-header">
          <h3>{title}</h3>
        </div>
        <div className="modal-body">
          <img src={image} alt={title} className="modal-image" />
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
          <h4>Projet Lier</h4>
            <ul>
              {projets.map(projet => (
                <li key={projet.name}>
                  <a href={projet.link} rel="noopener noreferrer" className='little__link'>{projet.name}</a>
                </li>
              ))}
            </ul>
          <div className="modal-details">
            <h4>Soft Skills</h4>
            <ul>
              {softSkills.map(skill => (
                <li key={skill.name}>
                  <a href={skill.link} rel="noopener noreferrer" className='little__link'>{skill.name}</a>
                </li>
              ))}
            </ul>
            <h4>Hard Skills</h4>
            <ul>
              {hardSkills.map(skill => (
                <li key={skill.name}>
                  <a href={skill.link} rel="noopener noreferrer" className='little__link'>{skill.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CardExperiences