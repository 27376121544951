import React, { useState } from 'react';
import "./header.css";

const Headers = () => {
  /* Toggle menu */
  const[Toggle, showMenu] = useState(false);
  return (
    <header className='header'>
        <nav className="nav container">
            <a href='#home' className='nav__logo'>Romain</a>
            <div className={Toggle ? "nav__menu show-menu": "nav__menu"}>
                <ul className="nav__list grid">
                    <li className="nav__item">
                        <a href="#home" className="nav__link active-link">
                            <i className="uil uil-home nav__icon"></i>
                            Accueil
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#about" className="nav__link">
                            <i className="uil uil-user nav__icon"></i>
                            À propos
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#skills" className="nav__link">
                            <i className="uil uil-file-alt nav__icon"></i>
                            Compétences
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#services" className="nav__link">
                            <i className="uil uil-briefcase nav__icon"></i>
                            Projets
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#qualification" className="nav__link">
                            <i className="uil uil-graduation-cap nav__icon"></i>
                            Diplôme-Expérience
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="/contact" className="nav__link">
                            <i className="uil uil-message nav__icon"></i>
                            Contact
                        </a>
                    </li>
                </ul>
                
                <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
            </div>

            <div className="nav__toggle" onClick={() => showMenu(!Toggle)}> <i className="uil uil-apps"></i></div>
        </nav>
    </header>
  );
};

export default Headers