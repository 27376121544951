import React from 'react';
import './skills.css';
import Frontend from './Frontend';
import Backend from './Backend';
import Soft from './Soft';

const Skills = () => {
  return (
    <section className="skills section" id="skills">
        <h2 className="section__title"><a href='/skills' className='little__link'>Mes compétences</a></h2>
        <span className="section__subtitle">Mon niveau technique</span>

        <div className="skills__container container grid">
          <Frontend />
          <Backend />
        </div>

        <span className="section__subtitle"></span>
        <div className="skills__container container grid">
          <Soft />
        </div>
        <h3 className="section__title"><a href='/skills'>Plus d'information ici</a></h3>
    </section>
  );
};

export default Skills