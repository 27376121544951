import React from 'react'

const NotFound = () => {
  return (
    <>
      <div>
          <h1>404 - Page non trouvée</h1>
          <p>La page que vous cherchez n'existe pas.</p>
          <a href='/'>Retour à l'accueil</a>
      </div>
    </>
  )
}

export default NotFound
