import React from 'react'

const Backend = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Backend developer</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name" id="php"><a href='/skills?id=php' className='little__link'>PHP</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=vuejs' className='little__link'>NODEJS</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name" id="python"><a href='/skills?id=python' className='little__link'>PYTHON</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name" id="sql"><a href='/skills?id=sql' className='little__link'>SQL</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=csharp' className='little__link'>C#</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=java' className='little__link'>JAVA</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Backend