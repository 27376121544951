import React from 'react'

const Frontend = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Frontend developer</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=htmlcss' className='little__link'>HTML</a></h3>
                    </div>
                </div>

                <div className="skills__data" id="react">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=react' className='little__link'>REACT</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=htmlcss' className='little__link'>CSS</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name" id="javascript"><a href='/skills?id=javascript' className='little__link'>JAVASCRIPT</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=bootstrap' className='little__link'>BOOTSTRAP</a></h3>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name"><a href='/skills?id=git' className='little__link'>GIT</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Frontend