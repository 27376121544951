import React from 'react'
import './contactlink.css';

const ContactLink = () => {
  return (
    <section className="contact section" id="contact">
        <h2 className="section__title"><a href='/contact' className='contact__link'>Me contacter</a></h2>
        <span className="section__subtitle"><a href='/contact' className='contact__link'>Suivez ce formulaire pour me contacter</a></span>
    </section>
  )
}

export default ContactLink