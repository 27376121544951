import React, { useState } from 'react';
import './header.css';
/*import { useLocation } from 'react-router-dom';*/

const HeadersV2 = () => {
     /* Toggle menu */
  const[Toggle, showMenu] = useState(false);
  /*const location = useLocation();*/

  return (
    <header className='header'>
        <nav className="nav container">
            <a href='/' className='nav__logo'>Romain</a>
            <div className={Toggle ? "nav__menu show-menu": "nav__menu"}>
                <ul className="nav__list grid">
                    <li className="nav__item">
                        <a href="/" className="nav__link active-link">
                            <i className="uil uil-home nav__icon"></i>
                            Accueil
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="/skills" className="nav__link">
                            <i className="uil uil-file-alt nav__icon"></i>
                            Compétences
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="/projects" className="nav__link">
                            <i className="uil uil-briefcase nav__icon"></i>
                            Projets
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="/experience" className="nav__link">
                            <i className="uil uil-briefcase nav__icon"></i>
                            Expérience
                        </a>
                    </li>
                    {/*location.pathname == "/projects" &&
                        <li className="nav__item">
                            <a href="#pro" className="nav__link">
                                <i className="uil uil-briefcase nav__icon"></i>
                                Projets Pro
                            </a>
                        </li>  ||
                        <li className="nav__item">
                            <a href="#scolaire" className="nav__link">
                                <i className="uil uil-briefcase nav__icon"></i>
                                    Projets Scolaire
                            </a>
                        </li> 
  */}
                    <li className="nav__item">
                        <a href="/contact" className="nav__link">
                            <i className="uil uil-message nav__icon"></i>
                            Contact
                        </a>
                    </li>
                </ul>
                
                <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
            </div>

            <div className="nav__toggle" onClick={() => showMenu(!Toggle)}> <i className="uil uil-apps"></i></div>
        </nav>
    </header>
  )
}

export default HeadersV2