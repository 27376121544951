import React, {useEffect, useState} from 'react';
import HeadersV2 from '../headers/HeadersV2';
import './experience.css'
import Card from '../card/CardExperiences';
import logoidverde from '../../assets/logoidverde.png';
import logoTelaie from '../../assets/teliae_logo.jpeg';

const experiences = [
    {
      idcard: 'master',
      image: logoTelaie,    
      title: 'Alternances Master - TELIAE',
      summary: 'Alternance de deux ans chez Teliae pour le Master EII, option Développement',
      description: "Durant les deux années de mon Master Expert en Ingénierie Informatique (EII), option Développement, chez Teliae, j'ai eu l'occasion de consolider et d'approfondir mes compétences techniques et professionnelles. J'ai participé à des projets de grande envergure, impliquant des technologies avancées et des architectures complexes. J'ai été impliqué dans toutes les phases du développement logiciel, de l'analyse des besoins à la maintenance et l'optimisation des systèmes. Cette expérience m'a permis de perfectionner mes compétences en gestion de projet, en leadership et en innovation technologique. J'ai également eu l'occasion de mentorat et de formation des nouveaux arrivants, renforçant ainsi mes compétences en gestion d'équipe et en transmission des connaissances. </br> <b>Retour sur l'expériences:</b> </br>Ces deux ans sont très importantes pour moi. J'ai pus être ouvert sur plein de projet, applications et langage différents. J'ai pus confirmer, via mon travail, mes compétences de développeur et mes connaisances dans le corps de métiers du transport. </br> Le fait d'avoir été sur plusieurs applications différentes ma permis de développer m'on adaptabilité et ma comprehension de nouveau langage et architecture.",
      softSkills: [
        { name: 'Collaboration', link: '/skills?id=collaboration' },
        { name: 'Innovation', link: '/skills?id=innovation' },
        { name: 'Esprit d\'équipe', link: '/skills?id=teamspirit' },
        { name: 'Empathie', link: '/skills?id=empathie' }],
      hardSkills: [
        { name: 'PHP', link: '/skills?id=php' },
        { name: 'SQL', link: '/skills?id=sql' },
        { name: 'Android', link: '/skills?id=java' },
        { name: 'JavaScript', link: '/skills?id=javascript' },
        { name: 'windev', link: '/skills?id=windev' },
        { name: 'HTML/CSS', link: '/skills?id=htmlcss' },
        { name: 'POO', link: '/skills?id=conceptionlogiciel' },
        { name: 'API', link: '/skills?id=webservice' }],
      projets: [
        { name: 'Evolution et maintien des applications', link: '/projects?id=projetTMS' }
      ]
    },
    {
      idcard: 'licence',
      image: logoTelaie,
      title: 'Alternances Licence - TELIAE',
      summary: "Alternance d'un an chez Teliae pour la Licence CDNT option SLAM",
      description: "Lors de ma première année d'alternance chez Teliae dans le cadre de ma Licence Conception et Développement de Nouvelles Technologies (CDNT), j'ai approfondi mes compétences en développement web et mobile. J'ai participé à plusieurs projets de développement, allant de la conception à la mise en production. Cette expérience m'a permis de me familiariser avec les technologies les plus récentes, ainsi que les méthodologies agiles. J'ai travaillé en étroite collaboration avec les équipes de conception et de développement, et j'ai acquis une compréhension approfondie du cycle de vie des projets informatiques. J'ai également amélioré mes compétences en communication, en gestion de projet et en résolution de problèmes complexes.</br> <b>Retour sur l'expériences:</b> </br> Durant ma licence j'ai fait 1 ans d'alternaces chez Teliae. J'ai vécu une expériences très importantes car j'ai vécu pour la première fois un ans completes en tant que développeur. Durant cette année j'ai pus m'imisser dans le rôle de développeur.</br> De plus j'ai pus faire preuve d'autonomies et de professionalisme durant le projet de la refonte de la page d'acceuil de Station Chargeur. Suite à une très bonen année j'ai décider de poursivre pour mon master chez Teliae.</br>",
      softSkills: [
        { name: 'Communication', link: '/skills?id=communication' },
        { name: 'Autonomie', link: '/skills?id=autonomie' },
        { name: 'Créativité', link: '/skills?id=creativite' },
        { name: 'Gestion de projet', link: '/skills?id=gestionprojet' },
        { name: 'Innovation', link: '/skills?id=innovation' },
        { name: 'Organisation', link: '/skills?id=organisation' },
        { name: 'Concéption', link: '/skills?id=conception' },
        { name: 'Documentation', link: '/skills?id=documentation' }],
      hardSkills: [
        { name: 'PHP', link: '/skills?id=php' },
        { name: 'JavaScript', link: '/skills?id=javascript' },
        { name: 'HTML/CSS', link: '/skills?id=htmlcss' },
        { name: 'progammation asynchrone', link: '/skills?id=webservice' }],
      projets: [
        { name: 'Evolution page d\'accueil de Station-Chargeur', link: '/projects?id=projetSC' }
      ]
    },
    {
      idcard: 'stage',
      image: logoidverde,
      title: 'Stage BTS SIO - IDVERDE',
      summary: "Développement d'une application web de gestion de stock pyrothechnique",
      description: "Au cours de mon stage de BTS Services Informatiques aux Organisations (SIO) chez IdVerde, j'ai eu l'opportunité de travailler sur le développement d'un outil de gestion des stocks. Mon rôle principal consistait à analyser les besoins des utilisateurs, à concevoir une solution logicielle adaptée et à en assurer la mise en œuvre. Grâce à cette expérience, j'ai acquis des compétences en développement d'applications, en gestion de bases de données, ainsi qu'en analyse fonctionnelle et technique. J'ai également appris à travailler en équipe et à respecter les délais serrés, tout en assurant la qualité et la fiabilité du produit final.</br> <b>Retour sur l'expériences:</b> </br> Cette expériences à été très précieuse, car c'était ma première expériences dans le monde professionels. </br> Je suis heureux d'avoir pus être immerger dans le travail d'équipe, équipe avec qui j'ai pus collaborer sur nos avancement et les taches à faires et en partageant nos avancements sur un repository commun à l'aide de git. </br> Je suis ressorti de celle-ci avec une nouvelles visions du travails de développeur et une envie d'aider des artisans à développer leur logiciels pour aider et facilité leur travails du quotidiens. </br></br>",
      softSkills: [
        { name: 'Communication', link: '/skills?id=communication' },
        { name: 'Autonomie', link: '/skills?id=autonomie' },
        { name: 'Gestion de projet', link: '/skills?id=gestionprojet' },
        { name: 'Innovation', link: '/skills?id=innovation' },
        { name: 'Organisation', link: '/skills?id=organisation' },
        { name: 'Concéption', link: '/skills?id=conception' },
        { name: 'Documentation', link: '/skills?id=documentation' }],
      hardSkills: [
        { name: 'PHP', link: '/skills?id=php' },
        { name: 'SQL', link: '/skills?id=sql' },
        { name: 'Bootstrap', link: '/skills?id=bootstrap' },      
        { name: 'Conception', link: '/skills?id=conceptionlogiciel' },
        { name: 'HTML/CSS', link: '/skills?id=htmlcss' }],
      projets: [
        { name: 'Somnium', link: '/projects?id=somnium' }
      ]
    }
  ];

const Experience = () =>  {
  const [selectedCardId, setSelectedCardId] = useState(null);

  useEffect(() => {
    const highlightProject = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const projectsId = urlParams.get('id');

      if (projectsId) {
        const projectsElement = document.getElementById(projectsId);
        if (projectsElement) {
          projectsElement.scrollIntoView({ block: "center" });

          // Add highlight class
          projectsElement.classList.add('highlight');
          // Remove highlight class after some time (e.g., 3 seconds)
          setTimeout(() => {
            projectsElement.classList.remove('highlight');
          }, 6000);
          
          // Set the selected card ID to open the modal
          setSelectedCardId(projectsId);
        }
      }
    };

    highlightProject();
  }, []);

  const handleCloseModal = () => {
    setSelectedCardId(null);
  };
    
    return (
      <>
        <HeadersV2 />
        
        <section className="projets section">
          <h3 id='projects'>Mes Expériences</h3>
          <div>
            <div id="experiences">
              <div className="projects-container">
                {experiences.map((project, index) => (
                  <Card
                    idcard={project.idcard}
                    image={project.image}
                    title={project.title}
                    summary={project.summary}
                    description={project.description}
                    softSkills={project.softSkills}
                    hardSkills={project.hardSkills}
                    showHandIcon={index === 0}
                    projets={project.projets}
                    isSelected={selectedCardId === project.idcard }
                    onCloseModal={handleCloseModal}
                  />
                ))}
              </div>
            </div>
          </div>
        </section> 
      </>
    )
  }

export default Experience