import React from 'react'

const Info = () => {
  return (
    <div className="about__info grid">
        <div className="about__box">
          <a href="#qualification" className="nav__link">
            <i className="bx bx-briefcase-alt about__icon"></i>
            <h3 className="about__title">Experience</h3>
            <span className="about__subtitle">3ans d'alternaces</span>
          </a>
        </div>

        <div className="about__box">
          <a href="#qualification" className="nav__link">
            <i className="bx bx-award about__icon"></i>
            <h3 className="about__title">Formations</h3>
            <span className="about__subtitle">Master 2 EII</span>
          </a>
        </div>

        <div className="about__box">
          <a href="#skills" className="nav__link">
            <i className="bx bx-support about__icon"></i>
            <h3 className="about__title">Skills</h3>
            <span className="about__subtitle">Full stack</span>
          </a>
        </div>
    </div>
  );
};

export default Info