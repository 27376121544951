import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Accueil from './components/accueil/Accueil';
import Projets from './components/projets/Projets';
import Competences from './components/competences/Competences';
import Contact from './components/Contact/Contact';
import Experience from './components/experiences/Experience';
import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
      <div>
        {/* 
        <nav>
          <ul>
            <li><Link to="/">Accueil</Link></li>
            <li><Link to="/skills">Compétences</Link></li>
            <li><Link to="/projects">Projets</Link></li>
          </ul>
        </nav>
        */}
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/skills" element={<Competences />} />
          <Route path="/projects" element={<Projets />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} /> {/* Route de secours pour 404 */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
