import React, { useState } from 'react';
import './qualification.css';

const Qualification = () => {
    const [toggleState, setToggleState] = useState(0);
    const toggleTab = (index) => {
        setToggleState(index);
    }
        
  return (
    <section className="qualification section" id="qualification">
        <h2 className="section__title"><a href="experience" className="qualification__link">Développeur</a></h2>
        <span className="section__subtitle">Mes diplômes passer et en cours</span>

        <div className="qualification__container container">
            <div className="qualification__tabs">
                <div className={toggleState  === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}
                onClick={() => toggleTab(1)}>
                    <i className="uil uil-graduation-cap qualification__icon"></i> Formation
                </div>
                <div className={toggleState  === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}
                onClick={() => toggleTab(2)}>
                    <i className="uil uil-briefcase-alt qualification__icon"></i> Expérience
                </div>
            </div>

            <div className="qualification__sections">
                {/* Formations */}
                <div className={toggleState  === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
                    <div className="qualification__data">
                        <div className="">
                                <h3 className="qualification__title"><a href="https://afip-formations.com/formation/expert-en-ingenierie-informatique/" className="qualification__link" target='_blank' rel="noopener noreferrer" >Expert en ingénieurie informatique - AFIP</a></h3>
                                <span className="qualification__subtitle">Master 2 en alternances</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"> 2022 - Présent</i>
                                </div>
                        </div>
                        <div className="">
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>
                    <div className="qualification__data">
                        <div></div>
                        <div className="">
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                        <div className="">
                            <h3 className="qualification__title"><a href="https://afip-formations.com/formation/bachelor-cyber-securite/" className="qualification__link" target='_blank' rel="noopener noreferrer" >Bachelor CDNT - AFIP</a></h3>
                            <span className="qualification__subtitle">Bachelor en alternances</span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"> 2021 - 2022</i>
                            </div>
                        </div>
                    </div>
                    <div className="qualification__data">
                        <div className="">
                            <h3 className="qualification__title"><a href="https://clsi.fr/nos-formations/bts-services-informatiques-aux-organisations-sio/" className="qualification__link" target='_blank' rel="noopener noreferrer" >BTS SIO - ICOF</a></h3>
                            <span className="qualification__subtitle">Formation en inital - Opt SLAM</span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"> 2019-2021</i>
                            </div>
                        </div>
                        <div className="">
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div></div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                        <div>
                            <h3 className="qualification__title">BAC STI2D</h3>
                            <span className="qualification__subtitle">Lycée Aragon</span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"> 2019</i>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Job */}
                <div className={toggleState  === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
                    <div className="qualification__data">
                        <div className="">
                                <h3 className="qualification__title"><a href="/experience?id=master" className="qualification__link" rel="noopener noreferrer" >Teliae - Alternance</a></h3>
                                <span className="qualification__subtitle"><a href="experience?id=master" className="qualification__link">Master 2 en alternance</a></span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"> 2022 - Présent</i>
                                </div>
                        </div>
                        <div className="">
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>
                    <div className="qualification__data">
                        <div></div>
                        <div className="">
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                        <div className="">
                            <h3 className="qualification__title"><a href="experience?id=licence" className="qualification__link" rel="noopener noreferrer" >Teliae - Alternance</a></h3>
                            <span className="qualification__subtitle"><a href="experience?id=licence" className="qualification__link">Bachelor en alternances</a></span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"> 2021 - 2022</i>
                            </div>
                        </div>
                    </div>
                    <div className="qualification__data">
                        <div className="">
                            <h3 className="qualification__title"><a href="experience?id=stage" className="qualification__link" target='_blank' rel="noopener noreferrer" >Stage IdVerde</a></h3>
                            <span className="qualification__subtitle"><a href='experience?id=stage'  className="qualification__link">BTS SIO</a></span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt">2021</i>
                            </div>
                        </div>
                        <div className="">
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div></div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                        <div>
                            <h3 className="qualification__title">Intérim</h3>
                            <span className="qualification__subtitle">--</span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"> 2019-2021</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
  )
}

export default Qualification